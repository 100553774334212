<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
        <el-breadcrumb-item>优质项目助发展 创业之“火”燃汕头 首届汕头创业之星大赛颁奖典礼暨创新创业国际高峰论坛圆满落幕</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <h2>优质项目助发展 创业之“火”燃汕头首届汕头创业之星大赛颁奖典礼暨创新创业国际高峰论坛圆满落幕</h2>
        <div class="second">
          <div class="time-img"><img src="@/assets/img/time.jpg" alt=""></div>
          <span class="time">2019/1/15</span>
          <span class="time-title">汕头.创业之星比赛</span>
          <span class="gold">三等奖
            <img src="@/assets/img/media.png" alt="">
          </span>
        </div>
        <p class="third-p1">1月14日，首届汕头创业之星大赛颁奖典礼暨创新创业国际高峰论坛在汕头市帝豪酒店圆满落幕。汕头市人民政府副市长林晓湧、副秘书长陈汉雄、汕头市科学技术局局长邱长奕、汕头高新区管委会副主任杨志刚、汕头市工业和信息化局副局长郑宗武，市直相关部门、汕头保税区、高新区、等各区县科技主管部门以及由深汕两地投资机构、深圳领军企业、投资机构、科技服务机构、通过大赛已落地汕头的科技企业组成的深圳科技创新代表团和孵化器、产业园区、创业者、媒体机构等300多人出席了活动。</p>
        <div class="fourth">
          <div class="fourth-img1"><img src="@/assets/img/first.jpg" alt=""></div>
          <div class="fourth-img2"><img src="@/assets/img/two.jpg" alt=""></div>
        </div>
        <p class="fourth-p2 fourth-center">汕头市人民政府副市长林晓湧接见获奖企业代表</p>
        <p class="fourth-p3">“真木网”、“智能健康管理和生命安全监控服务系统”、“纯电动汽车永磁同步电机关键技术的研发及产业化”、“‘健康+娱乐’智能耳机”、“i黑密智能激光生发头盔”、“面向智能投资的集中业务平台(QAMS PaaS)”项目共6个项目获得了本次大赛的优胜奖。</p>
        <p class="fourth-p3">投资意向签约仪式中，部分投资方代表和项目方代表上台进行投资意向签约，他们将会在赛后进行持续的沟通和交流，同时组委会也将建立相应的平台和渠道帮助更多的项目、技术、人才和资本的互联互通。</p>
        <div class="firth">
          <div><img src="@/assets/img/three.jpg" alt=""></div>
        </div>
        <p class="fourth-p2 fourth-center">三等奖获得企业代表和颁奖嘉宾合影留念</p>
        <p class="fourth-p2">在颁奖典礼上，“来画视频”、“创新型高功能医用伤口敷料”、“AInvest金融AI大数据分析”获得了三等奖</p>
        <div class="firth">
          <div><img src="@/assets/img/four.jpg" alt=""></div>
        </div>
        <p class="fourth-p2 fourth-center">二等奖获得企业“面向物联网应用的极低功耗wifi芯片和小基站射频芯片”和“快充长寿命1.5V锂电池”项目代表发表感言</p>
        <p class="fourth-p2">获得二等奖“面向物联网应用的极低功耗wifi芯片和小基站射频芯片”项目代表人在台上发表感言说“感谢汕头创业之星大赛这个平台，虽然是第一次到汕头但是印象特别好，未来有意愿能在汕头开分公司，为汕头的发展做贡献”；“快充长寿命1.5V锂电池”也在领奖时表示“项目主要的技术团队都来自清华大学，在细分领域的技术世界，我们是首创。我们的客户主要在汕头澄海区玩具厂，全球最大的玩具制造商。我们希望落户汕头，离我们客户更近一点，更好的合作。</p>
        <div class="fourth">
          <div class="fourth-img1"><img src="@/assets/img/five.jpg" alt=""></div>
          <div class="fourth-img2"><img src="@/assets/img/six.jpg" alt=""></div>
        </div>
        <p class="fourth-p2 fourth-center">汕头同行网络科技有限公司”获得一等奖</p>
        <p class="fourth-p2">最后，经过激烈的角逐和PK，汕头市同行网络科技有限公司的“同行大数据体育赛事平台”项目在大赛荣膺桂冠， 获得本届大赛一等奖。他发表感言说，“感谢大赛，给企业一个锻炼的机会，经过此次大赛，项目团队都有一个很大的提升。我们会继续努力，立足汕头，将创新创业的能量辐射到全省乃至于全国！”</p>
        <p class="fourth-p2">在之后举行的创新创业高峰论坛环节，广东省政协常委、广东省青少年成长导师、广东高科技产业商会执行会长，广东高科技产业商会金融俱乐部主席王理宗；零点有数董事长、上海市决咨委委员、零点青年公益创业发展中心理事长、欧美同学会常务理事、海归创业导师团团长袁岳；美籍在华创业者、美国智能直流微网的专家Derek以“理想与现实的距离有多远”、“新人种创造新物种”、“How to level up as a foreigner in shantou”三个主题进行了精彩的演讲。</p>
        <p class="fourth-p2 fourth-center fourth-color">"创业精神耀汕头 粤东大地放光芒"</p>
        <p class="fourth-p2">"本届汕头创业之星大赛项目数量创新高、来源分布广；项目数量达351家，创历届汕头同类创业大赛规模之最，省内市外项目共有130个，其中海外项目占比9%，达30个；其他城市（北京、上海、苏州、西安、中国香港、中国台湾等）项目占比25%，达90个。大赛不仅项目质量优，还初步显现出良好招商引资效果。40个晋级决赛的项目包含了3个美国项目，1个新西兰项目等在内的29个市外项目，涵盖信息技术、先进制造、生物医药、新能源新材料、互联网五大行业，许多项目属于高科技战略新兴行业，其中约20个项目已表达了落户汕头的意愿。</p>
        <p class="fourth-p2">此次大赛评审团多来自深圳一线投资机构，对汕头参赛优质项目表现出很大投资意愿；同时，投资人评委还主动为汕头优秀的参赛项目对接国内外其他市场资源，助力汕头创业企业迅猛发展。</p>
        <p class="fourth-p2">自大赛启动仪式以来，在南方日报、深圳特区报、汕头日报、羊城晚报、橄榄台等传统媒体发文30余篇，在新浪、网易、腾讯、今日头条、e京网、数据粤东等网络媒体展开大规模宣传，覆盖受众100万＋人次，实现全方位无死角覆盖。决赛及颁奖典礼上还有网易同期直播，观看人数达32万人次。</p>
        <p class="fourth-p2 fourth-center fourth-color">优质项目齐助力 汕头发展创辉煌</p>
        <p class="fourth-p2">来自新西兰团队的决赛项目“海洋超级铝基复材”的创始人Liu Huawu，和“节能建筑直流电源系统”项目创始人来自美国的Derek，通过大赛了解汕头,并看好汕头的发展优势，主动和组委会沟通提出希望汕头市政府的帮助和扶持该项目在汕头落地，成立汕头子公司，为汕头的未来发展贡献力量。</p>
        <p class="fourth-p2">来自深圳的国内领先的专业的金融技术服务商深圳前海乘势科技有限公司通过大赛在启动仪式之后落户汕头，他们着力于解决多个应用场景下的支付安全问题。</p>
        <div class="firth">
          <div><img src="@/assets/img/seven.jpg" alt=""></div>
        </div>
        <p style="text-align:right;margin-top:2rem;margin-bottom:0rem">原文链接：<a href="https://mp.weixin.qq.com/s/bcJB0Tq9U7iNGR-lpFHEfg">https://mp.weixin.qq.com/s/bcJB0Tq9U7iNGR-lpFHEfg</a></p>
      </div>

      <div class="right">
        <h3>相关报道</h3>
        <ul>
          <li>
            <a href="newtwo"><img src="@/assets/img/haibao.jpg" alt=""></a>
            <div class="middle">
              <a href="newtwo" class="h4">第六届“创青春”中国青年创新创业大赛（互联网组）全国赛圆满收官</a>
              <span class="middle-p1">中国青年创新创业大赛</span>
              <span class="middle-p2">金奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/10/24 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="neweight"><img src="@/assets/img/867.jpg" alt=""></a>
            <div class="middle">
              <a href="neweight" class="h4">中国（小谷围）人工智能创新创业大赛圆满落下帷幕</a>
              <span class="middle-p1">人工智能创新创业比赛</span>
              <span class="middle-p2">优胜奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/10 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newseven"><img src="@/assets/img/869.jpg" alt=""></a>
            <div class="middle">
              <a href="newseven" class="h4">创新创业大赛|决赛圆满告捷，冠军花落谁家</a>
              <span class="middle-p1">青年人才创新创业大赛</span>
              <span class="middle-p2">第三名
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/3/29 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      currentRouter: state => state.nav.currentRouter
    })
  },
  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
      console.log(1)
    }
  }

}
</script>

<style lang="scss" scoped>
.fourth-color{
  color: #333333 !important;
  font-weight: 900;
  letter-spacing: 0.5px !important;
}
.fourth-center{
  text-align: center;
}
p{
    text-align: left;
    text-indent:2em;
}
img{
     display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
  background-color: #f2f2f2;
}
.el-breadcrumb {
    line-height: 70px;
}

.main{
    margin:auto;
    padding: 30px;
    width: 1420px;
    overflow: hidden;
}
.left{
    width: 65%;
    padding: 30px;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(4, 0, 0, 0.15);
    border-radius: 20px;
    .second{
        overflow: hidden;
        margin-top: 40px;
    }
    .time-img{
        width: 20px;
        height: 20px;
        float: left;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .time{
        float: left;
        margin-left:10px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #999999;
    }
    .time-title{
        background-color: #eff3f5;
        border-radius: 4px;
        float: left;
        font-size: 14px;
        padding: 8px 11px 8px 9px;
        line-height: 10px;
        letter-spacing: 1px;
        color: #999999;
        margin-left: 40px;
        margin-right: 20px;
    }
    .gold{
    background-color: #ffd5d5;
    float: left;
    border-radius: 4px;
	font-size: 14px;
    padding: 8px 11px 8px 9px;
    line-height: 10px;
	letter-spacing: 1px;
    color: #ff3333;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: -14px;
    }
    }
    .third-p1{
    margin-top: 60px;
    font-size: 16px;
	line-height: 30px;
	letter-spacing: 0px;
	color: #666666;
    }
    .fourth{
        padding: 0 14px;
        div img{
            width: 375px;
            height: 251px;
        }
        .fourth-img1{
            margin-right: 14px;
            float: left;
        }
    }
       .fourth-p2{
           margin-top: 16px;
            font-family: PingFang-SC-Bold;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .fourth-p3{
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .firth{
            padding: 0 14px;
            div img{
                width: 767px;
                height: 511px;
            }
        }
}
ul{
  padding: 0
}
.right{
    width: 21.5%;
    margin-left: 25px;
    float: left;
    overflow: hidden;
    h3{
    font-size: 26px;
	line-height: 48px;
	letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-left: 30px;
    position: relative;
    }
    h3::before{
        display: block;
        content: '';
        width: 6px;
        height: 33px;
        background-color: #ff3333;
        position: absolute;
        top: 8px;
        left: -20px;
    }
    ul li{
        width: 100%;
        padding: 10px;
        background-color: #eff3f5;
        border-radius: 8px;
        margin: 15px 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 230px;
            border-radius: 8px;
        }
        .middle{
          width: 100%;
          margin-left: 8px;
          overflow: hidden;
          margin-bottom: 25px;
          span{
              float: left;
              padding: 0 9px;
          }
          .middle-p1{
              background-color: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #999999;
          }
          .middle-p2{
              background-color: #ffd5d5;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ff3333;
              margin-left: 25px;
              position: relative;
              img{
                  width: 20px;
                  height: 26px;
                  position: absolute;
                  top: 1px;
                  left: -15px;
              }
          }
        }
        .h4{
          display: block;
            width: 90%;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            margin: 16px 0;
        }
        .time{
            overflow: hidden;
            margin-right: 8px;
            img{
                width: 20px;
                height: 20px;
                float: right;
            }
            .time-word{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #999999;
                float: right;
                margin-left: 15px;
                margin-right: 5px;
            }
        }
    }
}
a:hover{
      text-decoration:none;
      color: #e45151  !important;
      transition: all 0.3s;
}
</style>
